import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo-gnu.png"
import flaguk from "../../assets/images/united-kingdom.png"
import flagit from "../../assets/images/italy.png"


const NavbarTwo = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area navbar-style-two">
                <div className="tarn-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link 
                                to="/"
                                onClick={() => setCollapsed(true)} 
                                className="navbar-brand"
                            >
                                <img src={logo} alt="logo" />
                            </Link>

                            <button 
                                onClick={toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>
                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link 
                                            to="/" 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            Home 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link 
                                            to="/about-us" 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            About us
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link 
                                            to="/services" 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            Services 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link 
                                            to="https://www.dinoapp.io" 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            DINO 
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link 
                                            to="/blog" 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            Blog
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link 
                                            to="https://academy.gnucoop.com" 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            Training 
                                        </Link>
                                    </li>

                                </ul>
                            
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        <Link 
                                            to="/contact" 
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-right"></i>Contact us<span></span>
                                        </Link>
                                    </div>
                                </div>
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        <Link 
                                            to="https://www.gnucoop.com/it/"
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            
                                        >
                                            <img src={flagit} width="40px" ></img>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NavbarTwo
