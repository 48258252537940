import React from 'react'
import { Helmet } from "react-helmet"
import favicon from '../../assets/images/logo.png'

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Gnucoop Soc. Coop. - Soluzioni digitali per l'economia sociale</title>
                <meta name="description" content="Gnucoop Soc. Coop. - Soluzioni digitali per l'economia sociale" />
                <meta name="og:title" property="og:title" content="Gnucoop Soc. Coop. - Soluzioni digitali per l'economia sociale"></meta>
                <meta name="twitter:card" content="Gnucoop Soc. Coop. - Soluzioni digitali per lo sviluppo sostenibile"></meta>
                <link rel="canonical" href="https://www.gnucoop.com/"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
	    	<link rel="icon" href={favicon} />
            </Helmet>
        </div>
    )
}

export default SEO
