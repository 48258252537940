import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/logo-gnu.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" />
                            </a>
                            <p><strong>Digital solutions for sustainable development.</strong><br/> More than 10 years at the service of Third Sector entities, NGOs, social enterprises and cooperatives.
 </p>

                            <ul className="social-link">
                                <li>
                                    <Link to="https://www.facebook.com/gnucoop" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.twitter.com/gnucoop" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/gnucoop_soc_coop" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/gnucoop/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.github.com/gnucoop/" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-github'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explore</h3>
                            
                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                    <li>
                                    <Link to="/about-us">
                                        About us 
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services">
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog">
                                        Our Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Resources</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="https://www.dinoapp.io">
                                        DINO
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/project/c2s">
                                        Cheese2School
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/digital-transformation">
                                        Smart Working
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/data-analysis">
                                        Data analysis
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/artificial-intelligence">
                                        AI
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Our Headquarter</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i> 
                                    via Stefanardo da Vimercate 28, <br /> 20128 Milano, Italia
                                </li>
                                <li>
                                    <i className='bx bx-phone-call'></i>
                                    <a href="tel:+393703309574">+393703309574</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="mailto:info@gnucoop.com">info@gnucoop.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-9 col-md-6">
                            <p><strong>Built with <i className='bx bx-heart'></i> -  @{currentYear} Gnucoop Soc. Coop. - VAT Number IT07931480961 - REA MI-1991512</strong></p>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;
